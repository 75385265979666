@import '../breakpoints.scss';
@import '../colors.scss';

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(20, 20, 20, 0.6);
}

.dialogRoot {
  position: absolute;
  left: 25%;
  right: 25%;
  width: 50%;
  margin-top: 10rem;
  padding: 1rem 4rem;
  min-height: 500px;
  max-height: 600px;
  background-color: $grey4;
  color: white;
  border-radius: 12px;
}

@media #{media-query(sm)}, #{media-query(md)} {
  .dialogRoot {
    padding: 1rem 1.5rem;
    left: 18%;
    right: 18%;
    width: 64%;
  }
}

@media #{media-query(xs)}, #{media-query(ss)} {
  .dialogRoot {
    max-height: unset;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    margin-top: 0;
  }
}

.dialogContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media #{media-query(xs)}, #{media-query(ss)} {
  .dialogContent {
    margin-top: 3rem;
  }
}

.dialogHeader {
  display: grid;
  grid-template-columns: [symbol] 3rem [name] 1fr;
  grid-template-rows: [first-row] 3.8rem [second-row] 1.2rem;
  align-items: center;
  margin-bottom: 1rem;
}

.coinName {
  grid-column: name;
  grid-row: first-row / second-row;
}

.coinSybmol {
  grid-column: symbol;
  grid-row: first-row / second-row;
}

.dialogHeader img {
  height: 35px;
}

.dialogBody {
  height: 70%;
  margin: 0 auto;
}

.dialogBody > div {
  margin-bottom: 2rem;
}

.coinDescription {
  text-align: justify;
  color: #c2c2c2;
}

@media #{media-query(ss)}, #{media-query(xs)}, #{media-query(sm)}, #{media-query(md)} {
  .coinDescription > span {
    font-size: 12px;
  }
}

.homepageLink a {
  text-decoration: underline;
}

.scrollbarView {
  height: 20vh;
}

.closeIcon {
  position: absolute;
  top: 1.2rem;
  right: 1.4rem;
  cursor: pointer;
}
