@import '../colors.scss';

.groupingHeaderRoot {
  display: grid;
  grid-template-columns: repeat(2, 1fr) repeat(4, 1fr) repeat(1, 1fr);
  background-color: $grey1;
  color: $grey5;
  font-weight: bold;
  margin-bottom: 2px;
}

.groupingHeaderRoot div:nth-child(1) {
  border-right: 2px solid $grey0;
  grid-column-start: 1;
  grid-column-end: span 2;
  text-align: center;
  line-height: 1rem;
}

.groupingHeaderRoot div:nth-child(2) {
  border-right: 2px solid $grey0;
  grid-column-start: 3;
  grid-column-end: span 4;
  text-align: center;
  line-height: 1rem;
}

.groupingHeaderRoot div:nth-child(3) {
  border-left: 2px solid $grey0;
  grid-column-start: 7;
  grid-column-end: 8;
  text-align: center;
  line-height: 1rem;
}
