$grey0: #0a0a0a;
$grey1: #141414;
$grey2: #222222;
$grey3: #262626;
$grey4: #333333;
$grey5: #666666;
$white: #ffffff;

$yellow: #dbcc34; // brand color
$greenTxt: rgb(44, 221, 171); // (”%” number)
$greenBg: rgb(44, 221, 171, 0.25);
$redTxt: rgb(255, 29, 99);
$redBg: rgb(255, 29, 99, 0.25);
