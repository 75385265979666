@import '../breakpoints.scss';
@import '../colors.scss';

.navbar {
  display: flex;
  justify-content: center;
  height: 10rem;
}

.navbar svg {
  width: inherit;
  height: inherit;
}

.active {
  background-color: $yellow;
}
