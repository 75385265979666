@import '../colors.scss';

.disclaimer {
  text-align: center;
}

.disclaimer p {
  line-height: 8px;
  font-size: 13px;
  color: $grey5;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
